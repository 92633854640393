var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inner"},[(!_vm.initLoading)?_c('div',[_c('div',{staticClass:"detail"},[_c('div',{staticClass:"vote-info"},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":_vm.voteDetail.img_url,"alt":""}})]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"name"},[_c('span',[_vm._v(_vm._s(_vm.voteDetail.name))])]),_c('div',{staticClass:"agree"},[_c('img',{attrs:{"src":require("@/assets/agree-2x.png"),"alt":""}}),_c('div',{staticStyle:{"color":"#0073EE"}},[_vm._v(_vm._s(_vm.voteDetail.agree))])]),_c('div',{staticClass:"dis-agree"},[_c('img',{attrs:{"src":require("@/assets/disagree-2x.png"),"alt":""}}),_c('div',{staticStyle:{"color":"#F96B1C"}},[_vm._v(_vm._s(_vm.voteDetail.oppose))])])])]),_c('div',{staticClass:"intro",style:(_vm.allInfo
            ? 'overflow:visible;max-height:none;-webkit-line-clamp: 100;'
            : 'overflow:hidden;'),attrs:{"id":"intro"},domProps:{"innerHTML":_vm._s(_vm.voteDetail.introduction)}}),(!_vm.allInfo && _vm.showMore)?_c('div',{staticClass:"is-more",on:{"click":function($event){return _vm.toMore(1)}}},[_vm._v(" "+_vm._s(_vm.$t('enroll.More'))+" "),_c('van-icon',{staticStyle:{"margin-left":"3px"},attrs:{"name":"arrow-down","color":"#0073EE"}})],1):_vm._e(),(_vm.allInfo && _vm.showMore)?_c('div',{staticClass:"is-more",on:{"click":function($event){return _vm.toMore(2)}}},[_vm._v(" "+_vm._s(_vm.$t('enroll.Read_Less'))),_c('van-icon',{staticStyle:{"margin-left":"3px"},attrs:{"name":"arrow-up","color":"#0073EE"}})],1):_vm._e(),_c('div',{staticClass:"line"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.voteDetail.is_vote == 2 ||
            (_vm.voteDetail.vote_data && _vm.voteDetail.vote_data.vote_type == 0)
        ),expression:"\n          voteDetail.is_vote == 2 ||\n            (voteDetail.vote_data && voteDetail.vote_data.vote_type == 0)\n        "}],staticClass:"btn"},[_c('div',{staticClass:"agree",on:{"click":function($event){return _vm.toVote(1)}}},[_vm._v(_vm._s(_vm.$t('enroll.Approve')))]),_c('div',{staticClass:"disagree",on:{"click":function($event){return _vm.toVote(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('enroll.Disapprove'))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.voteDetail.is_vote == 1 &&
            _vm.voteDetail.vote_data &&
            _vm.voteDetail.vote_data.vote_type == 1
        ),expression:"\n          voteDetail.is_vote == 1 &&\n            voteDetail.vote_data &&\n            voteDetail.vote_data.vote_type == 1\n        "}],staticClass:"btn"},[_c('div',{staticClass:"agree",staticStyle:{"border":"1px solid #0073EE","background-color":"#fff","color":"#0073EE"},on:{"click":function($event){return _vm.toVote(0)}}},[_vm._v(" "+_vm._s(_vm.$t('enroll.Cancel'))+" "+_vm._s(_vm.$t('enroll.Approve'))+" ")]),_c('div',{staticClass:"disagree",on:{"click":function($event){return _vm.toVote(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('enroll.Disapprove'))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.voteDetail.is_vote == 1 &&
            _vm.voteDetail.vote_data &&
            _vm.voteDetail.vote_data.vote_type == -1
        ),expression:"\n          voteDetail.is_vote == 1 &&\n            voteDetail.vote_data &&\n            voteDetail.vote_data.vote_type == -1\n        "}],staticClass:"btn"},[_c('div',{staticClass:"agree",on:{"click":function($event){return _vm.toVote(1)}}},[_vm._v(_vm._s(_vm.$t('enroll.Approve')))]),_c('div',{staticClass:"disagree",staticStyle:{"border":"1px solid #F96B1C","background-color":"#fff","color":"#F96B1C"},on:{"click":function($event){return _vm.toVote(0)}}},[_vm._v(" "+_vm._s(_vm.$t('enroll.Cancel'))+" "+_vm._s(_vm.$t('enroll.Disapprove'))+" ")])]),_c('div',{staticClass:"yta-num"},[_c('span',{staticClass:"one"},[_vm._v(_vm._s(_vm.$t('enroll.The_number_of_YTA_in_stake'))+" ")]),_c('span',{staticClass:"two"},[_vm._v(" "+_vm._s(_vm.voteDetail.trx_id == '00000' ? 0 : 1000000))])]),_c('div',{staticClass:"yta-num"},[_c('span',{staticClass:"one"},[_vm._v(_vm._s(_vm.$t('enroll.Staking_Address'))+" ")]),_c('span',{staticClass:"two"},[_vm._v(_vm._s(_vm.$route.query.account))])])]),_c('div',{staticClass:"vote-list"},[_c('votelistTwo',{ref:"voteTwo",attrs:{"voteMsg":_vm.voteMsg,"total":_vm.total,"type":"0"},on:{"fatherMea":_vm.getNoJoin}})],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading || _vm.initLoading),expression:"isLoading || initLoading"}],staticClass:"loading-box",style:(_vm.initLoading ? 'background-color:transparent;' : '')},[_c('van-loading',{staticStyle:{"z-index":"9999"},attrs:{"size":"24px","color":"#4994df","text-color":"#4994df"}},[_vm._v(_vm._s(_vm.$t('tip.loading')))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }