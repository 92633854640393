<template>
  <div class="inner">
    <div v-if="!initLoading">
      <div class="detail">
        <div class="vote-info">
          <div class="img-box">
            <img :src="voteDetail.img_url" alt="" />
          </div>
          <div class="info">
            <div class="name">
              <span>{{ voteDetail.name }}</span>
            </div>
            <div class="agree">
              <img src="@/assets/agree-2x.png" alt="" />
              <div style="color:#0073EE">{{ voteDetail.agree }}</div>
            </div>
            <div class="dis-agree">
              <img src="@/assets/disagree-2x.png" alt="" />
              <div style="color:#F96B1C">{{ voteDetail.oppose }}</div>
            </div>
          </div>
        </div>
        <div
          class="intro"
          v-html="voteDetail.introduction"
          id="intro"
          :style="
            allInfo
              ? 'overflow:visible;max-height:none;-webkit-line-clamp: 100;'
              : 'overflow:hidden;'
          "
        ></div>
        <div class="is-more" v-if="!allInfo && showMore" @click="toMore(1)">
          {{ $t('enroll.More') }}
          <van-icon
            name="arrow-down"
            color="#0073EE"
            style="margin-left:3px;"
          />
        </div>
        <div class="is-more" v-if="allInfo && showMore" @click="toMore(2)">
          {{ $t('enroll.Read_Less')
          }}<van-icon
            name="arrow-up"
            color="#0073EE"
            style="margin-left:3px;"
          />
        </div>
        <div class="line"></div>
        <div
          class="btn"
          v-show="
            voteDetail.is_vote == 2 ||
              (voteDetail.vote_data && voteDetail.vote_data.vote_type == 0)
          "
        >
          <div class="agree" @click="toVote(1)">{{ $t('enroll.Approve') }}</div>
          <div class="disagree" @click="toVote(-1)">
            {{ $t('enroll.Disapprove') }}
          </div>
        </div>
        <div
          class="btn"
          v-show="
            voteDetail.is_vote == 1 &&
              voteDetail.vote_data &&
              voteDetail.vote_data.vote_type == 1
          "
        >
          <div
            class="agree"
            style="border: 1px solid #0073EE;background-color:#fff;color:#0073EE;"
            @click="toVote(0)"
          >
            {{ $t('enroll.Cancel') }}
            {{ $t('enroll.Approve') }}
          </div>
          <div class="disagree" @click="toVote(-1)">
            {{ $t('enroll.Disapprove') }}
          </div>
        </div>
        <div
          class="btn"
          v-show="
            voteDetail.is_vote == 1 &&
              voteDetail.vote_data &&
              voteDetail.vote_data.vote_type == -1
          "
        >
          <div class="agree" @click="toVote(1)">{{ $t('enroll.Approve') }}</div>
          <div
            class="disagree"
            style="border: 1px solid #F96B1C;background-color:#fff;color:#F96B1C;"
            @click="toVote(0)"
          >
            {{ $t('enroll.Cancel') }}
            {{ $t('enroll.Disapprove') }}
          </div>
        </div>
        <!-- 抵押yta数量 -->
        <div class="yta-num">
          <span class="one"
            >{{ $t('enroll.The_number_of_YTA_in_stake') }} &nbsp;</span
          >
          <span class="two">
            {{ voteDetail.trx_id == '00000' ? 0 : 1000000 }}</span
          >
        </div>
        <div class="yta-num">
          <span class="one">{{ $t('enroll.Staking_Address') }} &nbsp;</span>
          <span class="two">{{ $route.query.account }}</span>
        </div>
      </div>
      <div class="vote-list">
        <votelistTwo
          :voteMsg="voteMsg"
          :total="total"
          type="0"
          @fatherMea="getNoJoin"
          ref="voteTwo"
        ></votelistTwo>
      </div>
    </div>

    <div
      class="loading-box"
      v-show="isLoading || initLoading"
      :style="initLoading ? 'background-color:transparent;' : ''"
    >
      <van-loading
        size="24px"
        color="#4994df"
        text-color="#4994df"
        style="z-index:9999;"
        >{{ $t('tip.loading') }}</van-loading
      >
    </div>
  </div>
</template>

<script>
import { getUserData } from '@/api/enroll'
import votelistTwo from '@/components/voteListTwo'
import { getVoteRecordDataByAccount } from '@/api/enroll'
import { isUserVote } from '@/utils/index'
import { Toast } from 'vant'
export default {
  inject: ['reload'],
  components: {
    votelistTwo,
  },
  data() {
    return {
      voteDetail: {},
      showMore: true, //是否展示更多按钮
      allInfo: false,
      allPoints: '',
      total: 0,
      voteMsg: [],
      isLoading: false,
      initLoading: true,
    }
  },
  methods: {
    getData() {},
    toMore(num) {
      if (num == 1) {
        this.allInfo = true
      } else {
        this.allInfo = false
      }
    },
    async getNoJoin(start) {
      let obj = {}
      obj.start = start
      obj.limit = '10'
      obj.account = this.$route.query.account
      let res = await getVoteRecordDataByAccount(obj)
      this.total = res.total
      this.voteMsg.push(...res.data)
    },
    // 投票操作
    async toVote(num) {
      console.log(num)
      if (!this.$store.state.account.name) {
        Toast(this.$t('tip.your_main_account_not_found'))
        return
      }
      this.isLoading = true
      // this.$refs.voteTwo.showList = false
      let res = await isUserVote(this.voteDetail.id, num)
      if (res.code == 0) {
        setTimeout(() => {
          this.isLoading = false
          if (num == 1) {
            Toast.success(this.$t('enroll.vote_successfully'))
          } else if (num == -1) {
            Toast.success(this.$t('enroll.vote_successfully'))
          } else {
            Toast.success(this.$t('enroll.Vote_Cancelled'))
          }
        }, 1000)
        setTimeout(() => {
          this.reload()
        }, 2500)
      } else {
        this.isLoading = false
      }
    },
    async isGettingVote() {
      let res = await getUserData({
        account: this.$route.query.account,
        vote_account: this.$store.state.account.name || '',
      })
      if (res.code == 0) {
        this.voteDetail = res.data
        this.$nextTick(function() {
          let intro = document.getElementById('intro')
          console.log(intro.offsetHeight) //输出：修改后的值
          if (intro.offsetHeight > 170) {
            this.showMore = true
            this.allInfo = false
          } else {
            console.log(intro.offsetHeight)
            this.showMore = false
            this.allInfo = true
          }
        })
        this.isLoading = false
      }
    },
    isClearStr(str) {
      if (str.slice(0, 11) == '<p><br></p>') {
        str = str.replace('<p><br></p>', '')
        this.isClearStr(str)
      } else {
        this.voteDetail.introduction = str
        return
      }
    },
  },
  async created() {
    console.log(this.$store.state.account.name)
    let res = await getUserData({
      account: this.$route.query.account,
      vote_account: this.$store.state.account.name || '',
    })
    if (res.code == 0) {
      this.initLoading = false
      this.voteDetail = res.data
      this.isClearStr(this.voteDetail.introduction)
      console.log(this.voteDetail)
      this.$nextTick(function() {
        let intro = document.getElementById('intro')
        console.log(intro.offsetHeight) //输出：修改后的值
        if (intro.offsetHeight > 170) {
          this.showMore = true
          this.allInfo = false
        } else {
          console.log(intro.offsetHeight)
          this.showMore = false
          this.allInfo = true
        }
      })
    } else {
      this.initLoading = false
    }
  },
}
</script>

<style lang="less" scoped>
/deep/ .van-loading {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner {
  padding-top: 15px;
  .detail {
    width: 93.333%;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 17px 10px 22px 10px;
    .vote-info {
      display: flex;
      margin-bottom: 18px;
      .img-box {
        width: 100%;
        height: 102px;
        border-radius: 6px;
        max-width: 102px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
      }
      .info {
        margin-left: 14px;
      }
      .name {
        padding-left: 5px;
        margin-top: 6px;
        font-weight: 400;
        color: #181a33;
        height: 45px;
        font-size: 16px;
      }
      .agree,
      .dis-agree {
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 12px;
        align-items: center;
        margin-bottom: 9px;
        img {
          width: 16px;
          height: 16px;
        }
        div {
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          box-sizing: border-box;
          margin-left: 4px;
        }
      }
    }
    .intro {
      margin-bottom: 12px;
      max-height: 178px;
      box-sizing: border-box;
      padding-left: 9px;
      padding-right: 4px;
      font-size: 14px;
      line-height: 18px;
      color: #636363;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
    }
    .is-more {
      color: #0073ee;
      margin-left: 9px;
    }
    .line {
      margin-top: 16px;
      height: 1px;
      background-color: #f2f2f2;
    }
    .btn {
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .agree {
        width: 47.742%;
        text-align: center;
        line-height: 40px;
        background: #0073ee;
        border-radius: 5px;
        font-size: 14px;
        color: #fff;
        box-sizing: border-box;
      }
      .disagree {
        width: 47.742%;
        text-align: center;
        line-height: 40px;
        background-color: #f96b1c;
        border-radius: 5px;
        font-size: 14px;
        color: #fff;
        box-sizing: border-box;
      }
    }
  }
}
// 投票的loading
.loading-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.yta-num {
  padding-left: 9px;
  box-sizing: border-box;
  padding-top: 12px;
  padding-bottom: 14px;
  border-bottom: 1px solid #eeeeee;
  .one {
    font-size: 14px;
    color: #181a33;
    font-weight: bold;
  }
  .two {
    color: #0073ee;
    font-size: 14px;
    margin-left: 8px;
  }
}
</style>
